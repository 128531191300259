import Debug from 'debug';

import { getUserClientCookieData } from '../auth/browser';
import fetchJson, { FetchJsonOptions } from '../fetchJson';
import navigateWindow from '../utils/navigateWindow';
import { ApiErrorCodes } from '../errors/types';

import { SongwhipApiPayload } from './types';

const debug = Debug('songwhip/songwhipApi');

const SONGWHIP_API_ENDPOINT = process.env.SONGWHIP_API_ENDPOINT;

export interface SongwhipApiOptions extends FetchJsonOptions {
  token?: string;

  /**
   * Whether user is forced to /logout on 403/401
   * response status code.
   *
   * @default true
   */
  logoutOnForbidden?: boolean;
}

const songwhipApi = async <Data = any>(
  path: string,
  { token, logoutOnForbidden = true, ...restOptions }: SongwhipApiOptions = {}
) => {
  const url = toUrl(path);

  debug('fetch', url, restOptions);

  const fetchJsonOptions = {
    ...restOptions,

    headers: {
      ...restOptions.headers,
    },
  };

  // Add the 'secret' user-agent to indicate the request is trusted
  // to bypass cloudflare rate-limiter. This shouldn't leak to browser bundles.
  if (!process.browser) {
    fetchJsonOptions.headers['user-agent'] =
      'songwhip-trusted-client/songwhip-web-server';

    fetchJsonOptions.headers['referer'] = 'songwhip-web-server';
  }

  // Add auth header if token provided. This is only used serverside as
  // the client doesn't actually have access to the raw token as it's
  // stored in `HttpOnly` cookie. Clientside requests will include the
  // token cookie automatically which is used by the /api/* proxy.
  if (token) {
    fetchJsonOptions.headers['authorization'] = `Bearer ${token}`;
  }

  try {
    const { json, status, headers } = await fetchJson<SongwhipApiPayload<Data>>(
      url,
      fetchJsonOptions
    );

    debug('response %s', status);

    const fromApiCache = headers.get('cf-cache-status') === 'HIT';

    return { json, fromApiCache };
  } catch (error) {
    const { status, code } = error;
    const isForbidden = status === 403 || status === 401;

    const orchardAdminRequiresAccount =
      code === ApiErrorCodes.ORCHARD_ADMIN_REQUIRES_ACCOUNT;

    if (process.browser) {
      // HACK: a crude way of booting a user out when they have reached
      // a page page they shouldn't or their auth token is faulty. A more
      // sophisticated approach would be to do this check further up the stack
      // but that would covering all the places songwhipApi is called, at
      // least this approach addresses the issue once at the source.
      if (isForbidden && logoutOnForbidden) {
        debug('forbidden: logging out');
        const isLoggedIn = !!getUserClientCookieData()?.userId;

        if (isLoggedIn) {
          navigateWindow('/logout');
        }
      }

      if (orchardAdminRequiresAccount) {
        navigateWindow('/account/pick');
      }
    }

    throw error;
  }
};

const toUrl = (path: string) => {
  // serverside we hit the final service directly
  if (!process.browser) {
    return new URL(path, SONGWHIP_API_ENDPOINT).toString();
  }

  // clientside we go via the /api/songwhip/ proxy to avoid CORs
  // issues and having to handshake with a separate origin
  const url = new URL(path, `http://x/api/songwhip/`);

  return url.pathname + url.search;
};

export default songwhipApi;
