import ApiError, { ApiErrorParams } from './ApiError';

export type AppError = ApiError | Error;

export type SerializedAppError =
  | ApiErrorParams
  | { message: string; stack?: string };

export enum ApiErrorCodes {
  ORCHARD_ADMIN_REQUIRES_ACCOUNT = 'ORCHARD_ADMIN_REQUIRES_ACCOUNT',
  PRERELEASE_ALREADY_EXISTS = 'PRERELEASE_ALREADY_EXISTS',
}
