import mapAlbum from '~/app/lib/mapper/mapAlbum';

import songwhipApi from '../songwhipApi';
import { ItemConfig } from '../types';

export * from './getAll';

type GetParams = {
  albumId: number;
};

export const getAlbumApi = async ({ albumId: id }: GetParams) => {
  const {
    json: { data },
  } = await songwhipApi(`albums/${id}`);

  return mapAlbum(data);
};

type LocalizeParams = {
  albumId: number;
  country: string;
};

export const localizeAlbumApi = async ({
  albumId: id,
  country,
}: LocalizeParams) => {
  const {
    json: { data },
  } = await songwhipApi(`albums/${id}/localize`, {
    method: 'POST',
    body: { country },
  });

  return mapAlbum(data);
};

type RefreshParams = {
  albumId: number;
  force?: boolean;
  country?: string;
};

export const refreshAlbumApi = async ({
  albumId: id,
  ...params
}: RefreshParams) => {
  const {
    json: { data },
  } = await songwhipApi(`albums/${id}/refresh`, {
    method: 'POST',
    body: params,
  });

  return mapAlbum(data);
};

export const deleteAlbumApi = async ({ albumId: id }: { albumId: number }) => {
  const {
    json: { data },
  } = await songwhipApi(`albums/${id}`, {
    method: 'DELETE',
  });

  return mapAlbum(data);
};

export const patchAlbumConfigApi = async ({
  albumId,
  config,
}: {
  albumId: number;
  config: ItemConfig;
}) => {
  if (!albumId) throw new Error('`albumId` required');

  const {
    json: { data },
  } = await songwhipApi(`albums/${albumId}/config`, {
    method: 'PATCH',
    body: config,
  });

  return mapAlbum(data);
};

export const patchAlbumApi = async ({
  albumId,
  changes,
  showToastOnError,
}: {
  albumId: number;
  showToastOnError?: boolean;
  changes: {
    name?: string;
    releaseDate?: string;
    upc?: string;
    sourceUrl?: string;
    isDraft?: boolean;
  };
}) => {
  if (!albumId) throw new Error('`albumId` required');

  const {
    json: { data },
  } = await songwhipApi(`albums/${albumId}`, {
    method: 'PATCH',
    body: changes,
    showToastOnError,
  });

  return mapAlbum(data);
};
