import {
  AlbumPayload,
  ArtistPayload,
  TrackPayload,
} from '../songwhipApi/types';
import { toTimestamp } from '../utils/date';

import { OwnedByAccountsMapped } from './types';

export const mapOwnedByAccounts = (
  payload:
    | AlbumPayload
    | ArtistPayload
    | TrackPayload
    | AlbumPayload['artists'][0]
): OwnedByAccountsMapped | undefined => {
  return payload.ownedByAccounts?.map(({ subscriptionEndsAt, ...rest }) => ({
    ...rest,

    // avoid timezone ambiguity by converting to timestamp upfront
    subscriptionEndsAtTimestamp: toTimestamp(subscriptionEndsAt),
  }));
};
