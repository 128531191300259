import { AlbumPayload, GetAllApiPayload, GetAllData } from '../../types';
import mapAlbum from '~/app/lib/mapper/mapAlbum';

export default ({
  data,
  links = {},
}: GetAllApiPayload<AlbumPayload>): GetAllData => ({
  items: data.map(mapAlbum),
  nextPageApiUrl: links.next,
  prevPageApiUrl: links.prev,
});
