import { AlbumPayload, GetAllApiParams, GetAllApiPayload } from '../../types';
import { toQueryString } from '~/app/lib/router2';
import songwhipApi from '../../songwhipApi';

import mapper from './mapper';

const ITEMS_PER_PAGE = 100;

export const getAllAlbumsApi = async ({
  afterId,
  beforeId,
  direction = 'desc',
  limit = ITEMS_PER_PAGE,
}: GetAllApiParams) => {
  const params: any = { limit: String(limit) };

  if (afterId) params.afterId = afterId;
  if (beforeId) params.beforeId = beforeId;
  if (direction) params.direction = direction;

  const payload = (await songwhipApi(`albums?${toQueryString(params)}`))
    .json as GetAllApiPayload<AlbumPayload>;

  return mapper(payload);
};
