import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { toItemPagePath } from '~/app/lib/router2/utils';
import { AlbumPayload, ArtistPayload } from '../songwhipApi/types';
import { toTimestamp } from '../utils/date';
import { ItemTypes } from '~/types';

import { MappedArtist, MappedPartialAlbum } from './types';
import { mapOwnedByAccounts } from './utils';

const mapArtist = (
  payload: ArtistPayload | AlbumPayload['artists'][0]
): MappedArtist => {
  return removeUndefinedKeys<MappedArtist>({
    type: ItemTypes.ARTIST,
    id: payload.id,
    path: payload.path!,
    pagePath: toItemPagePath(payload.path!),
    name: payload.name,
    image: payload.image || undefined,
    links: payload.links || undefined,
    description: payload.description,
    linksCountries: payload.linksCountries,
    sourceCountry: payload.sourceCountry!,
    deleted: payload.deleted,
    spotifyId: payload.spotifyId || undefined,
    ownedByAccounts: mapOwnedByAccounts(payload),

    // custom config
    config: payload.config || undefined,

    createdAtTimestamp: toTimestamp(payload.createdAt),
    refreshedAtTimestamp: toTimestamp(payload.refreshedAt),
    customLinks: (payload as ArtistPayload).customLinks || undefined,
    albums: toAlbums(payload as any),
    videos: (payload as ArtistPayload).videos,
    shows: (payload as ArtistPayload).shows,
  });
};

const toAlbums = ({ albums }: ArtistPayload) => {
  if (!albums) return;

  const albumsByName = albums.reduce<
    Record<string, ArtistPayload['albums'][0]>
  >((result, album) => {
    const existing = result[album.name];

    // If the existing album with the same name is 'not clean'
    // prefer it. Essentially this means we use the original/explicit
    // duplicate items over the clean ones.
    if (existing && existing.isExplicit !== false) {
      return result;
    }

    result[album.name] = album;

    return result;
  }, {});

  return (
    Object.values(albumsByName)
      .map((item) => ({
        ...item,
        releaseDate: toTimestamp(item.releaseDate),
        pagePath: toItemPagePath(item.path),
        image: item.image,
      }))
      // remove albums w/o a release date or image to make
      // so downstream components don't need to handle this
      .filter(({ releaseDate, image }) => {
        return !!(releaseDate && image);
      })
      // sort newest first
      .sort((item1, item2) => {
        return item1.releaseDate! > item2.releaseDate! ? -1 : 1;
      }) as MappedPartialAlbum[]
  );
};

export default mapArtist;
