import removeUndefinedKeys from '../utils/removeUndefinedKeys';
import { toItemPagePath } from '../router2/utils';
import { ItemTypes } from '~/types';

import artistMapper from './mapArtist';
import { toTimestamp } from '../utils/date';
import { mapOwnedByAccounts } from './utils';
import { MappedAlbum } from './types';
import { AlbumPayload } from '../songwhipApi/types';

const mapAlbum = (payload: AlbumPayload) =>
  removeUndefinedKeys<MappedAlbum>({
    type: ItemTypes.ALBUM,
    id: payload.id,
    path: payload.path,
    pagePath: toItemPagePath(payload.path),
    name: payload.name,
    image: payload.image || undefined,
    links: payload.links,
    linksCountries: payload.linksCountries || undefined,
    sourceCountry: payload.sourceCountry!,
    releaseDate: payload.releaseDate!,
    upc: payload.upc,

    // handle mapping serialized Tracks that don't have artists defined
    // (eg. in edgeWorker responding to on-record-change hook)
    artists: payload.artists?.map(artistMapper),

    createdAtTimestamp: toTimestamp(payload.createdAt),
    refreshedAtTimestamp: toTimestamp(payload.refreshedAt),

    config: payload.config || undefined,
    isPrerelease: payload.isPrerelease,
    isDraft: payload.isDraft,

    ownedByAccounts: mapOwnedByAccounts(payload),
    customLinks: payload.customLinks,
  });

export default mapAlbum;
